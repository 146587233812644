<template>
    <div>
		<page>
			<section style="padding: 10px;">
				<div class="css-content_center css-text-lg">
					<span>{{ reporte.empresa }}</span>
				</div>

				<div class="css-content_center css-text-md">
					<span class="css-text-12">{{ reporte.direccion }}</span>
					<br>
					<span class="css-text-12">TEL: {{ reporte.telefono }}</span>
					<br>
					<span class="css-text-12">{{ reporte.ubicacion }}</span>
				</div>

				<div class="css-content_center css-text-normal">
					<span>{{ reporte.documento }}</span>
				</div>

				<div class="arial_font" align="center">
					<span style="font-weight: 500;">Fecha: {{ reporte.fechaSalida }} {{ reporte.horaSalida }}</span>
				</div>
				<br>
				<div class="arial_font">
					<span>Codigo: <span class="css-text-12">{{ reporte.codigo }}</span></span>
				</div>
				<br>
				<div class="arial_font">
					<span>Gasto de: <span class="css-text-12"><span align="center" style="font-size:26px;">{{ reporte.importeSalida }}  {{ reporte.simbolo }}</span></span></span>
				</div>
				<div>
					<br>Son: <span class="css-text-12">{{ reporte.montoliteral }}</span>
				</div>
				<br>
				<div class="arial_font">
					<span>Detalle: <span class="css-text-12">{{ reporte.detalleSalida }}</span></span>
				</div>
				<br>
				<div class="arial_font">
					<span>Concepto: <span class="css-text-12">{{ reporte.plancuenta.nombre }}</span></span>
				</div>
				<br>
				<div class="arial_font" align="center">
					<span style="text-transform: uppercase; font-size: 11px;">ATENDIDO POR: {{ reporte.usuario.usuario }}</span><br>
					<span style="text-transform: uppercase; font-size: 11px;">{{ reporte.almacen.nombre }}</span><br>
					<span style="font-size: 11px;">Reporte impreso por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span><br>
					<br>
					<span>GRACIAS POR SU PREFERENCIA</span>
				</div>

				<div class="css-no_print" align="center">
					<br>
					<a-button onclick="print_default_printer()" type="primary"><a-icon type="printer" /> IMPRIMIR</a-button>
				</div>
			</section>
		</page>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.9); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'

  export default {

      props: {
          salidacajaID: { type: String, required: true }
      },

      data () {
          return {
			 utils,
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('salidacaja', ['reporte'])
      },

      methods: {
          ...mapActions('salidacaja', ['getReporte']),

		  imprimir () {
			 this.spinnerloading = true
			 this.getReporte(this.salidacajaID)
			 .then(response => {
                this.spinnerloading = false
             }).catch(error => {
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
             })
		  }
      },

	  mounted () {
		  this.imprimir()
	  }
  }
</script>

<style>
	@page { size: 95mm; size: portrait; } /* output size */
	body.receipt .sheet { width: 95mm; height: auto; } /* sheet size */
	@media print { body.receipt { width: 95mm} } /* fix for Chrome */

	/* reset chapter and figure counters on the body */
	body {
		counter-reset: chapternum figurenum;
		line-height: 1;
		height: 100%;
		color: #000000;
		font-weight:700;
		background: rgb(204,204,204); 
	}

	page {
		width: 9.5cm;
		height: auto; 
		background: white;
		display: block;
		margin: 0 auto;    
	}
	
	@media print {
		body, page {
			margin: 0;
			box-shadow: 0;
		}
	}

	a {
		color: #000;
	}

	.css-text-lg {
		font-size: 21px;
	}

	.css-text-md {
		font-size: 13px;
	}

	.css-text-normal {
		font-size: 17px;
	}

	.css-text-12 {
		font-size: 13px;
		font-weight: 500 !important;
	}

	.css-content_center {
		text-align: center;
	}

	.css-header_table {
		/*border: 1px solid #000;*/
		border-top: 1px solid;
		border-bottom: 1px solid;
	}

	.css-footer_table {
		/*border: 1px solid #000;*/
		border-top: 1px solid;
	}

	.css-info_table {
		width: 100%;
	}

	.css-info_table td {
		padding-right: 2px;
		padding-left: 2px;
	}

	.css-text_table {
		background-color: #ffffff; 
		border-top: 0px solid #ffffff; 
		padding: 5px;
	}

	.css-products_data_table {
		width: 100%;
		font-size: 0.85em;
	}

	.css-products_data_table tfoot {
		border-top: 1px solid;
	}

	.css-footer_text {
		font-size: 0.7em;
	}

	.css-text_left {
		text-align: left;
	}

	.css-text_center {
		text-align: center;
	}

	.css-text_right {
		text-align: right;
	}

	.arial_font {
		font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	}
</style>